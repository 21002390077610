<template>
  <div>
    <!-- Banner -->
    <banner
      :back-image="bannerImage"
      height="600"
    />

    <div class="container my-5">

      <!-- Title -->
      <h1 class="text-center">
        {{ $t('security.title') }}
      </h1>

      <p> {{ $t('security.text1') }} </p>

      <p> {{ $t('security.text2') }} </p>

      <p> {{ $t('security.text3') }} </p>

      <p> {{ $t('security.text4') }} </p>

      <ol>

        <li> {{ $t('security.subtext1') }} </li>

        <li> {{ $t('security.subtext2') }} </li>

        <li> {{ $t('security.subtext3') }} </li>

        <li> {{ $t('security.subtext4') }} </li>

        <li> {{ $t('security.subtext5') }} </li>

        <li> {{ $t('security.subtext6') }} </li>

        <li> {{ $t('security.subtext7') }} </li>

      </ol>

      <b-row>
        <b-col class="d-flex align-items-center justify-content-center">
          <img
            class="securityCertificate"
            :src="certificate"
            alt="Certificate"
          >
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import Banner from 'Components/Common/Banner.vue'
import MyData from 'Data/commitments.json'

import { BRow, BCol } from 'bootstrap-vue'

export default {
  name: 'Security',
  components: {
    Banner,
    BRow,
    BCol,
  },
  data() {
    return {
      bannerImage: MyData.data.securityImage,
      certificate: MyData.data.securityCertificate,
    }
  },
}
</script>

<style scoped>

  .securityCertificate {
    width: 50%;
  }

  @media (max-width: 499px){
    .securityCertificate {
      width: 100%;
    }
  }

</style>
